import { JsonPipe } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { lucideExternalLink } from '@ng-icons/lucide';
import { watchState } from '@ngrx/signals';

import { createPolicy } from 'app/common/services/legacy/policies/policies.factory';

import { AppConfigModule, AppContext } from '../../../app-config';
import { PolicyStore, PolicyStoreState } from '../../services/legacy/policies/policies.store';
import '@kin/web-components/dist/external/kin-accordion/kin-accordion';

declare global {
  interface Window {
    __KIN_DEBUG__: {
      policyStore: {
        get: () => PolicyStoreState;
        update: (partialState: PolicyStoreState) => void;
        reset: () => void;
      };
    };
  }
}

@Component({
  selector: 'app-debug-policy-state',
  imports: [AppConfigModule, ReactiveFormsModule, JsonPipe, NgIconComponent],
  providers: [provideIcons({ lucideExternalLink })],
  template: `
    @if (notProd) {
      <div class="p-300">
        <h4>
          <a class="link text-link" href="https://kininsurance.atlassian.net/wiki/spaces/AAR/pages/1137278977/Quoting+UI+Debugger+Guide#Policy-State" target="_blank"
            >What is on this tab?<ng-icon name="lucideExternalLink" size="1em" class="ml-1 align-super" aria-hidden="true"></ng-icon
          ></a>
        </h4>
        <div class="mt-4 text-sm">
          <p>Open Chrome DevTools console and use these commands to manipulate the store:</p>
          <pre class="mt-2 rounded bg-gray-100 p-2">
// Get current state
window.__KIN_DEBUG__.policyStore.get()

// Update state (merges with existing)
window.__KIN_DEBUG__.policyStore.update({{ '{' }}
  deductibles: [{{ '{' }} id: 'wildfire_deductible', value: '10%' {{ '}' }}]
{{ '}' }})

// Reset to initial state
window.__KIN_DEBUG__.policyStore.reset()</pre
          >
        </div>
        <kin-accordion class="mt-4">
          <h4 slot="label">Current Policy State</h4>
          <div class="card max-h-[30rem] overflow-scroll bg-gray-100">
            <pre><code class="text-xs">{{ currentStateSignal() | json }}</code></pre>
          </div>
        </kin-accordion>
      </div>
      <div class="p-300">
        <div class="card">
          <label class="textarea">
            <span class="textarea__label">Inject Policy State</span>
            <textarea [formControl]="policyStateInjectFormControl" class="textarea__control" placeholder="Policy State JSON" rows="15"> </textarea>
          </label>
          @if (errorMsg) {
            <div class="form-help form-help--invalid">{{ errorMsg }}</div>
          }
          <button (click)="injectPolicyState()" class="button button--secondary button--sm mt-100">Inject State</button>
        </div>
      </div>
    }
  `,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DebugPolicyStateComponent {
  private policyStore = inject(PolicyStore);

  @AppContext() private _appContext!: AppContext;
  public notProd = this._appContext.notProd;
  public errorMsg: undefined | string = undefined;
  public currentStateSignal = signal<object>({});
  initialState = {
    data: createPolicy(),
    status: 'success',
    error: null,
  };
  public policyStateInjectFormControl = new FormControl<string | undefined>(JSON.stringify(this.initialState, null, 2));

  constructor() {
    if (this._appContext.notProd) {
      this.setupDevTools();
    }

    watchState(this.policyStore, () => {
      this.currentStateSignal.set({
        data: this.policyStore.data(),
        status: this.policyStore.status(),
        error: this.policyStore.error(),
      });
    });
  }

  private setupDevTools() {
    window.__KIN_DEBUG__ = {
      policyStore: {
        get: () => ({
          data: this.policyStore.data(),
          status: this.policyStore.status(),
          error: this.policyStore.error(),
        }),
        update: (partialState: PolicyStoreState) => {
          const currentState = this.policyStore.data();
          if (typeof partialState === 'object') {
            this.policyStore.setData({ ...currentState, ...partialState });
          }
        },
        reset: () => {
          this.policyStore.setData(createPolicy());
          this.policyStore.updateStatus('success');
          this.policyStore.setError(null);
        },
      },
    };
  }

  public injectPolicyState() {
    const policyStateValue = this.policyStateInjectFormControl.value;
    try {
      if (this.policyStateInjectFormControl.valid && policyStateValue) {
        const partialState = JSON.parse(policyStateValue);
        const currentState = {
          data: this.policyStore.data(),
          status: this.policyStore.status(),
          error: this.policyStore.error(),
        };

        // If a complete state object is provided, use it directly
        if (partialState.data || partialState.status || partialState.error) {
          const newState = {
            ...currentState,
            ...partialState,
          };
          if (newState.data) this.policyStore.setData(newState.data);
          if (newState.status) this.policyStore.updateStatus(newState.status);
          if (newState.error) this.policyStore.setError(newState.error);
        } else {
          // Otherwise, treat it as a partial update to the data property
          const newData = {
            ...currentState.data,
            ...partialState,
          };
          this.policyStore.setData(newData);
        }
        this.errorMsg = undefined;
      }
    } catch (e) {
      this.errorMsg = 'Failed to update policy state. Please ensure your JSON is properly formatted.';
      console.error('Policy state injection error:', e);
    }
  }
}
